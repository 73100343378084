import React from 'react';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { CgShoppingCart } from 'react-icons/cg';
import { useCartAtom } from '@/atoms/useCartAtom';
import { ShoppingCartItem } from '@components/Pack/ShoppingCartItem';
import Link from 'next/link';
import { PRICE_ID_REVENUE, SINGLE_PACK_PRICE_ID } from '@data/constants';
import { ScrollArea } from '@components/ui/scroll-area';

interface CartDetailsSheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  href: string;
  variant?: 'icon' | 'button';
  className?: string;
}

export function CartDetailsSheet({ open, onOpenChange, href, variant = 'icon', className }: CartDetailsSheetProps) {
  const { cart, totalItems, updateThemeQuantity, removeTheme } = useCartAtom();
  const { themes, model } = cart;

  const renderTrigger = () => {
    if (variant === 'icon') {
      return (
        <Button variant="ghost" size="icon" className="relative">
          <CgShoppingCart className="h-5 w-5" />
          {totalItems > 0 && (
            <span className="absolute -top-1 -right-1 bg-accent text-accent-foreground rounded-full text-xs w-4 h-4 flex items-center justify-center">
              {totalItems}
            </span>
          )}
        </Button>
      );
    } else {
      return (
        <Button
          size={'xl'}
          variant="outline"
          className={`relative border-foreground/10 hover:bg-foreground/10 ${className}`}
        >
          <CgShoppingCart className="h-5 w-5 mr-2" />
          Cart ({totalItems})
        </Button>
      );
    }
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger className="w-full md:w-fit">{renderTrigger()}</SheetTrigger>
      <SheetContent onOpenAutoFocus={e => e.preventDefault()}>
        <div className="flex flex-col h-full">
          <SheetHeader>
            <SheetTitle>Cart Details</SheetTitle>
            <SheetDescription>Review the items in your cart.</SheetDescription>
            <Link href={href} passHref className="w-full">
              <Button className="w-full" variant="accent">
                Proceed to Checkout
              </Button>
            </Link>
          </SheetHeader>

          <ScrollArea>
            <div className="flex flex-col gap-2">
              {themes?.map(theme => (
                <ShoppingCartItem
                  size="sm"
                  key={theme.id}
                  title={theme.name}
                  mainImageSrc={theme.image || '/images/pack-covers/blank.jpg'}
                  clothing={theme.selectedModifiers?.clothing || ''}
                  price={PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]}
                  isAdmin={true}
                  quantity={theme.quantity || 1}
                  model={model ? model.trainingMeta?.readableName! : ''}
                  modelAvatar={model ? model.trainingMeta?.trainingImages?.[0]! : ''}
                  onQuantityChange={quantity => {
                    updateThemeQuantity(theme.id, quantity);
                  }}
                  onRemove={() => {
                    removeTheme(theme.id);
                  }}
                  modelVersion={model?.version as '1.5' | 'flux.1-dev'}
                />
              ))}
            </div>
          </ScrollArea>
        </div>
      </SheetContent>
    </Sheet>
  );
}
