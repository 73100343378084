import * as React from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import PicStudioLogo from '@components/PicStudioLogo/PicStudioLogo';
import { ModeToggle } from '@components/ModeToggle/ModeToggle';
import cn from 'classnames';
import Link from 'next/link';
import { useSession } from '@hooks/index';
import { Button } from '@components/ui/button';
import { getPaymentsCount } from '@/_data/payments/getCount';
import AvatarMenu from './AvatarMenu';
import SidebarProfileLink from '@components/Sidebar/SidebarProfileLink';
import { api } from '@lib/api';
import { useCartAtom } from '@/atoms/useCartAtom';
import { CartDetailsSheet } from '@components/Sheets/cart-details';

const navigation = [
  { name: 'Photo Packs', href: '/packs/browse' },
  { name: 'Reviews', href: '/examples-and-reviews' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Referral Program', href: '/refer' }
];

interface MinimalNavProps {
  className?: string;
  width?: 'default' | 'full';
  hideNavigation?: boolean; // New prop to hide navigation
}

export default function MinimalNav({ className, width = 'default', hideNavigation = false }: MinimalNavProps) {
  const { isLoggedIn } = useSession();
  const userCreditsQuery = api.userCredits.get.useQuery(
    {},
    {
      enabled: isLoggedIn
    }
  );
  const { totalItems } = useCartAtom();
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const [paymentsCount, setPaymentsCount] = React.useState(0);
  const [isLoadingPayments, setIsLoadingPayments] = React.useState(true);

  const canGoToDashboard = React.useMemo(() => {
    if (isLoadingPayments) return false;
    if (paymentsCount > 0) return true;
    if (Number(userCreditsQuery.data?.modelCredits || 0) > 0) return true;
    return false;
  }, [isLoadingPayments, paymentsCount, userCreditsQuery.data]);

  React.useEffect(() => {
    if (!isLoggedIn) return;
    const getCount = async () => {
      setIsLoadingPayments(true);
      const count = await getPaymentsCount();
      setPaymentsCount(count);
      setIsLoadingPayments(false);
    };

    getCount();
  }, [isLoggedIn]);

  return (
    <Disclosure as="nav" className={`bg-background inset-x-0 relative z-10 ${className}`}>
      {({ open }) => (
        <>
          <div
            className={cn('px-2 md:px-8 flex flex-col w-full m-auto gap-6', {
              'max-w-screen-2xl': width === 'default'
            })}
          >
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-primary/20 text-primary hover:text-primary focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="  flex flex-1 lg:flex-initial w-fit items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link href="/">
                    <PicStudioLogo className="hover:opacity-80" />
                  </Link>
                </div>
              </div>

              {!hideNavigation && (
                <div className="hidden lg:flex lg:absolute w-fit  items-center justify-center left-0 right-0 m-auto lg:gap-x-4">
                  {navigation.map(item => (
                    <Link
                      key={item.name}
                      href={item.href}
                      // className="text-sm font-semibold leading-6 text-primary hover:opacity-80"
                    >
                      <Button variant="ghost" size="sm">
                        {item.name}
                      </Button>
                    </Link>
                  ))}
                </div>
              )}

              <div className="  hidden absolute right-0 lg:flex items-center sm:static gap-x-2">
                <ModeToggle className="hidden px-3 md:flex ring-0 hover:bg-muted shadow-none" />

                {/* Add the cart icon button here */}
                {isLoggedIn && (totalItems || 0) > 0 ? (
                  <CartDetailsSheet open={isCartOpen} onOpenChange={setIsCartOpen} href="/packs/review" />
                ) : null}

                {!isLoggedIn ? (
                  <>
                    {' '}
                    <Link href="/login">
                      <Button variant="outline" size="sm">
                        Log in
                      </Button>
                    </Link>
                    <Link href="/login">
                      <Button variant="accent" size="sm">
                        Sign up
                      </Button>
                    </Link>
                  </>
                ) : canGoToDashboard ? (
                  <Link href="/dashboard" className="text-sm font-semibold leading-6 text-primary">
                    <Button variant="ghost" size="sm">
                      Dashboard
                    </Button>
                  </Link>
                ) : (
                  <Link href="/pricing" className="text-sm font-semibold leading-6 text-primary">
                    <Button variant="ghost" size="sm" className="min-w-[100px]">
                      Buy a pack
                    </Button>
                  </Link>
                )}
                {isLoggedIn && <AvatarMenu />}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="bg-background space-y-1 px-2 pb-3 pt-2 dark:shadow-[rgba(0,_0,_0,_0.9)_0px_20px_40px_-7px] backdrop-filter backdrop-blur-xl ">
              {!isLoggedIn ? (
                <Link href="/login">
                  <Disclosure.Button className="w-full justiy-start flex leading-6 text-primary rounded-md px-3 py-2 text-base font-medium hover:bg-muted hover:text-primary">
                    Log in
                  </Disclosure.Button>
                </Link>
              ) : (
                <Link href="/dashboard">
                  <Disclosure.Button className="w-full justiy-start flex leading-6 text-primary rounded-md px-3 py-2 text-base font-medium hover:bg-muted hover:text-primary">
                    Dashboard
                  </Disclosure.Button>
                </Link>
              )}
              {!hideNavigation &&
                navigation.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={cn(
                      'hover:bg-muted hover:text-primary',
                      'text-primary block rounded-md px-3 py-2 text-base font-medium'
                    )}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              <div className="px-2">
                {isLoggedIn ? (
                  <SidebarProfileLink />
                ) : (
                  <div className="w-full flex flex-col gap-y-4 my-6">
                    <Link href="/login" className="w-full">
                      <Button variant={'accent'} className="w-full">
                        Sign up
                      </Button>
                    </Link>
                    <div className="w-full flex flex-col mt-4">
                      <div className="m-auto text-xs text-muted-foreground">Already have an account?</div>
                      <Link href="/login" className="w-full">
                        <Button variant={'link'} className="w-full text-accent">
                          Log in
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
