import { Avatar, AvatarImage, AvatarFallback } from '@components/ui/avatar';
import { useSession } from '@hooks/index'; // Import the useSession hook
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover';
import TButton from '@components/Button/TButton';
import { IoLogOut } from 'react-icons/io5';
import React from 'react';

export default function AvatarMenu() {
  const { user, logOut, isLoggedIn } = useSession(); // Get the user object from useSession hook
  const userName = user?.name || 'Guest'; // Get the user's name or use 'Guest' as the default

  return (
    <>
      {isLoggedIn && (
        <Popover>
          <PopoverTrigger>
            <Avatar className="w-8 h-8">
              <AvatarImage src={user?.image} alt={userName} />
              <AvatarFallback>{userName.charAt(0)}</AvatarFallback>
            </Avatar>
          </PopoverTrigger>
          <PopoverContent>
            <div className="w-48">
              <div className="flex flex-col items-center justify-start p-4">
                <Avatar className="w-12 h-12 mb-4">
                  <AvatarImage src={user?.image} alt={userName} />
                  <AvatarFallback>{userName.charAt(0)}</AvatarFallback>
                </Avatar>
                {userName}
              </div>

              <TButton
                variant="ghost"
                size="small"
                className="w-full !justify-start !text-left cursor-pointer"
                onClick={() => logOut()}
              >
                <IoLogOut /> Log Out
              </TButton>
            </div>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}
